import { isNil } from 'lodash-es';
import _dayjs from './day';
import { BIRTHDAY_FORMAT, BIRTHDAY_ERROR } from '../shop/constants/user';

export const getJsMonth = (month) => (isNil(month) ? month : month - 1);

// Note: the expected month is 0-based, same as js Date

const dayjs = _dayjs.utc;
const LEAP_YEAR = 2000;
const MONTH_MAX_DAY = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const getMaxDate = (year, month) => {
  if (!isNil(month) && (month < 0 || month > 11)) {
    throw Error(`invalid month: ${month}`);
  }

  if (!isNil(year) && !isNil(month)) {
    return dayjs().set('year', year).set('month', month).endOf('month').date();
  }

  if (!isNil(month)) {
    return MONTH_MAX_DAY[month];
  }

  return 31;
};

export const validateBirthday = ({
  birthdayFormat,
  year,
  month,
  date,
  minAge,
  nullable = false,
}) => {
  if ([year, month, date].every(isNil)) {
    return nullable ? null : BIRTHDAY_ERROR.REQUIRED;
  }

  switch (birthdayFormat) {
    case BIRTHDAY_FORMAT.YYYYMMDD: {
      if (isNil(year) || isNil(month) || isNil(date)) {
        return BIRTHDAY_ERROR.INVALID;
      }
      if (!dayjs(`${year}/${month + 1}/${date}`, 'YYYY/M/D', true).isValid()) {
        return BIRTHDAY_ERROR.INVALID;
      }

      const birthday = dayjs()
        .set('year', year)
        .set('month', month)
        .set('date', date);
      if (birthday.isAfter(dayjs())) {
        return BIRTHDAY_ERROR.INVALID;
      }
      if (!isNil(minAge)) {
        const minDay = dayjs().subtract(minAge, 'year');
        if (birthday.isAfter(minDay)) {
          return BIRTHDAY_ERROR.MIN_AGE;
        }
      }
      break;
    }
    case BIRTHDAY_FORMAT.YYYYMM: {
      if (isNil(year) || isNil(month)) {
        return BIRTHDAY_ERROR.INVALID;
      }
      if (!dayjs(`${year}/${month + 1}`, 'YYYY/M', true).isValid()) {
        return BIRTHDAY_ERROR.INVALID;
      }

      const birthday = dayjs()
        .set('year', year)
        .set('month', month)
        .startOf('month');
      if (birthday.isAfter(dayjs())) {
        return BIRTHDAY_ERROR.INVALID;
      }
      if (!isNil(minAge)) {
        const minDay = dayjs().subtract(minAge, 'year').endOf('month');
        if (birthday.isAfter(minDay)) {
          return BIRTHDAY_ERROR.MIN_AGE;
        }
      }
      break;
    }
    case BIRTHDAY_FORMAT.MMDD:
      if (isNil(month) || isNil(date)) {
        return BIRTHDAY_ERROR.INVALID;
      }
      if (
        !dayjs(`${LEAP_YEAR}/${month + 1}/${date}`, 'YYYY/M/D', true).isValid()
      ) {
        return BIRTHDAY_ERROR.INVALID;
      }
      break;
    default:
      throw Error(`unknown birthdayFormat: ${birthdayFormat}`);
  }

  return null;
};

export const getFormattedBirthday = (year, month, date) => {
  if (!isNil(year) && !isNil(month) && !isNil(date)) {
    return dayjs()
      .set('year', year)
      .set('month', month)
      .set('date', date)
      .format('YYYY/MM/DD');
  }
  if (!isNil(year) && !isNil(month)) {
    return dayjs().set('year', year).set('month', month).format('YYYY/MM');
  }
  if (!isNil(month) && !isNil(date)) {
    return dayjs()
      .set('year', LEAP_YEAR)
      .set('month', month)
      .set('date', date)
      .format('MM/DD');
  }

  return null;
};

// birthday stored in db may not consistent with birthdayFormat, need to be filtered in frontend
export const getFilteredBirthday = ({ birthdayFormat, year, month, date }) => {
  return {
    year: birthdayFormat === BIRTHDAY_FORMAT.MMDD ? null : year,
    month,
    date: birthdayFormat === BIRTHDAY_FORMAT.YYYYMM ? null : date,
  };
};

export const getBirthdayFieldValue = ($field) => {
  if ($field.val()) {
    // field value is in `number:1994` string form
    return Number($field.val().split(':')[1]);
  }
  return null;
};
