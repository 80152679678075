export const BIRTHDAY_FORMAT = {
  YYYYMMDD: 'YYYY/MM/DD',
  YYYYMM: 'YYYY/MM',
  MMDD: 'MM/DD',
};

export const BIRTHDAY_ERROR = {
  REQUIRED: 'REQUIRED',
  INVALID: 'INVALID',
  MIN_AGE: 'MIN_AGE',
};
